
import { sendGtagEvent } from "@/common/common";
import { Capacitor } from "@capacitor/core";
import NativeLanding from "./LandingPage/NativeLanding.vue";
import WebLanding from "./LandingPage/WebLanding.vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ogImage from "@/assets/ogimg.png";

import { defineComponent } from "vue";

export default defineComponent({
  head() {
    return {
      title: "gophrr - RVA Outing planner",
      meta: [
        {
          name: "description",
          content:
            "gophrr helps you find specials and plan events. View nearby deals, schedule events with friends and more. ",
        },
        {
          property: "og:description",
          content:
            "Richmond, VA (RVA) Happy hours, event planning, and more on the gophrr app",
        },
        {
          property: "og:title",
          content: "Get the gophrr app",
        },
        {
          property: "og:image",
          content: "https://gophrr.io/" + ogImage,
        },
        {
          property: "og:url",
          content: "https://gophrr.io",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:site_name",
          content: "gophrr",
        },
      ],
    };
  },
  data() {
    return {
      isNative: Capacitor.isNativePlatform(),
    };
  },
  components: {
    WebLanding,
    NativeLanding,
  },
  methods: {
    sendToVenues(id: number) {
      sendGtagEvent(this.$gtag, "clicked_cta", {
        eventLabel: "cta_click_find_specials_" + id.toString(),
        event_category: "engagement",
        value: id.toString(),
      });

      this.$router.push({ name: "Venues" });
    },
    sendToLogin(id: number) {
      sendGtagEvent(this.$gtag, "clicked_cta", {
        eventLabel: "cta_click_start_planning_" + id.toString(),
        event_category: "engagement",
        value: id.toString(),
      });
      this.$router.push({ name: "Login" });
    },
  },
});
