import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isNative ? 'NativeLanding' : 'WebLanding'), {
      onSendToVenues: _ctx.sendToVenues,
      onSendToLogin: _ctx.sendToLogin
    }, null, 40, ["onSendToVenues", "onSendToLogin"]))
  ]))
}